import { axios } from '../utils/axios';

export const getEventGuests = ({ id, page, pageSize, isAttended, search }) =>
  axios.get(`events/${id}/event-guests`, {
    params: {
      page,
      pageSize,
      filter: {
        isAttended,
        search,
      },
    },
  });

export const getEventGuest = (id) => axios.get(`event-guests/${id}`);

export const addEventGuest = (eventId, name, referenceCode) =>
  axios.post(`event-guests`, {
    eventId,
    name,
    referenceCode,
  });

export const eventGuestAttended = (id) =>
  axios.post(`event-guests/${id}/attended`);
