import QRCode from 'react-qr-code';

const EventGuestInvitation = ({ event, eventGuest }) => {
  return (
    <div style={{ marginTop: '50px', maxWidth: '250px', textAlign: 'center' }}>
      <h5 style={{ color: '#333' }}>Event Invitation</h5>
      <p style={{ margin: '0' }}>{event.name}</p>
      <h6 style={{ marginTop: '2px', marginBottom: '10px' }}>{event.date}</h6>
      <h6 style={{ marginTop: '2px', marginBottom: '16' }}>PT. PACIFIC MEDAN INDUSTRI</h6>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <QRCode value={eventGuest.id.toString()} size={150} />
      </div>
      <p>{eventGuest.name}</p>
    </div>
  );
};

export default EventGuestInvitation;
