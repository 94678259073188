import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';

const ProtectedRoute = ({ element, allowedRoles }) => {
    const { authData } = React.useContext(AuthContext);
    const hasAllowedRoles = allowedRoles.includes(authData.role);

    return hasAllowedRoles ? element : <Navigate to="/403" />;
};

export default ProtectedRoute;
