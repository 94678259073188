import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import DashboardLayout from '../pages/dashboard/DashboardLayout';
import Events from '../pages/event/Events';
import Event from '../pages/event/Event';
import CelebrationIcon from '@mui/icons-material/Celebration';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ScanUserGuestInvitation from '../pages/scan/ScanUserGuestInvitation';
import RequireAuth from '../components/RequireAuth';
import ProtectedRoute from './ProtectedRoute';
import { UserRole } from '../constants/user_role';

const DashboardRoutes = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  const ROUTES = [
    {
      name: 'Scan Invitation',
      path: '/scan-invitation',
      allowedRoles: [UserRole.SUPER_ADMIN, UserRole.RECEPTIONIST],
      component: (
        <ScanUserGuestInvitation
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
        />
      ),
      icon: <QrCodeScannerIcon />,
      showOnNavDrawer: true,
    },
    {
      name: 'Events',
      path: '/events',
      allowedRoles: [UserRole.SUPER_ADMIN],
      component: (
        <Events
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
        />
      ),
      icon: <CelebrationIcon />,
      showOnNavDrawer: true,
    },
    {
      name: 'Event Detail',
      path: '/events/:id',
      allowedRoles: [UserRole.SUPER_ADMIN],
      component: (
        <Event
          isNavigationDrawerOpen={isDrawerOpen}
          onMenuButtonClick={handleDrawerOpen}
        />
      ),
      showOnNavDrawer: false,
    },
  ];

  return (
    <Routes>
      <Route
        element={
          <RequireAuth>
            <DashboardLayout
              isDrawerOpen={isDrawerOpen}
              routes={ROUTES}
              onMinimizeButtonClick={handleDrawerClose}
            />
          </RequireAuth>
        }
      >
        {ROUTES.map((route, routeIndex) => (
          <Route
            path={route.path}
            element={
              <ProtectedRoute
                element={route.component}
                allowedRoles={route.allowedRoles}
              />
            }
            key={routeIndex}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default DashboardRoutes;
