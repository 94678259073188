import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import MainRoute from './routes';

function App() {
  return (
    <AuthProvider>
      <MainRoute />
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
