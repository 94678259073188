/* eslint-disable react-hooks/rules-of-hooks */
import {
  ChevronLeft as ChevronLeftIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { useContext } from 'react';
import { DRAWER_WIDTH } from '../../constants/navigationDrawerConstants';
import DrawerHeader from '../../components/DrawerHeader';
import AuthContext from '../../contexts/AuthContext';

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const NavigationDrawer = ({ isOpen, onMinimizeButtonClick, routes }) => {
  const isRouteMatch = (path) =>
    useMatch(useResolvedPath(path).pathname) !== null;
  const { authData, logOut } = useContext(AuthContext);

  const handleLogOut = async () => {
    if (isOpen) {
      onMinimizeButtonClick();
    }
    logOut();
  };

  return (
    <Drawer variant="permanent" open={isOpen}>
      <DrawerHeader>
        <IconButton
          onClick={onMinimizeButtonClick}
          sx={{ color: 'drawer.main' }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider sx={{ bgcolor: 'drawer.light', opacity: 0.06 }} />
      <List sx={{ flex: 1 }}>
        {routes
          .filter((route) => route.showOnNavDrawer && route.allowedRoles.includes(authData.role))
          .map((route, routeIndex) => (
            <ListItem
              disablePadding
              key={routeIndex}
              sx={
                isRouteMatch(route.path)
                  ? {
                    borderLeft: 3,
                    color: 'drawer.light',
                  }
                  : { color: 'drawer.main' }
              }
            >
              <ListItemButton
                component={Link}
                to={route.path}
                selected={isRouteMatch(route.path)}
                sx={{
                  borderRadius: 0,
                  '&.Mui-selected': {
                    bgcolor: 'drawer.highlight',
                  },
                }}
              >
                <ListItemIcon
                  sx={
                    isRouteMatch(route.path)
                      ? { color: 'drawer.light' }
                      : { ml: 0.375, color: 'drawer.main' }
                  }
                >
                  {route.icon}
                </ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Stack sx={{ mb: 5, mx: 1.687, alignItems: 'center' }} direction="row">
        <Box
          component="img"
          src="https://pfpmaker.com/_nuxt/img/profile-3-1.3e702c5.png"
          sx={{ width: '2.8125rem', height: '2.8125rem', objectFit: 'cover' }}
        />
        <Box sx={{ flex: 1, ml: 2 }}>
          <Typography variant="caption">
            Logout
          </Typography>
          <Typography sx={{ fontSize: '0.625rem' }}>
            {authData?.user?.name}
          </Typography>
        </Box>
        <IconButton sx={{ color: 'drawer.light' }} onClick={handleLogOut}>
          <LogoutIcon />
        </IconButton>
      </Stack>
    </Drawer>
  );
};

export default NavigationDrawer;
