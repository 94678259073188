const LOCAL_STORAGE_PREFIX = 'guest-book-';

const storage = {
  getToken: () => {
    return localStorage.getItem(`${LOCAL_STORAGE_PREFIX}token`);
  },
  setToken: (token) => {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}token`, token);
  },
  removeToken: () => {
    localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}token`);
  },
};

export default storage;
