import { useEffect, useState } from "react";
import { getEventGuest, eventGuestAttended } from "../../api/eventGuest";
import HeaderBar from "../../components/HeaderBar";
import { Html5QrcodeScanner } from "html5-qrcode";
import DrawerHeader from "../../components/DrawerHeader";
import { Box, Button, Typography } from "@mui/material";
import SuccessAttendanceModal from "../../components/SuccessAttendanceModal";
import FailureAttendanceModal from "../../components/FailureAttendanceModal";
import dayjs from "dayjs";

const ScanUserGuestInvitation = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
    const [eventGuest, setEventGuest] = useState(null);
    const [isAttendedLoading, setIsAttendedLoading] = useState(false);
    const [isSuccessAttendanceModalOpen, setIsSuccessAttendanceModalOpen] = useState(false);
    const [failureAttendanceModalDescription, setFailureAttendanceModalDescription] = useState(null);

    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            fps: 5,
        });

        scanner.render(success, error);

        function success(result) {
            scanner.clear();
            getEventGuest(result)
                .then((response) => {
                    setEventGuest(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching user information:", error);
                });
        }

        function error(err) {
            console.warn(err);
        }

        // cleanup function when component will unmount
        return () => {
            scanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, []);

    const handleConfirmAttendance = () => {
        if (eventGuest) {
            setIsAttendedLoading(true);
            eventGuestAttended(eventGuest.id)
                .then((response) => {
                    setIsSuccessAttendanceModalOpen(true);
                })
                .catch((error) => {
                    if (error.response.data.meta) {
                        setFailureAttendanceModalDescription(error.response.data.meta.message);
                    } else {
                        setFailureAttendanceModalDescription("Something went wrong");
                    }
                    console.error("Error confirming attendance:", error); // Handle the error
                }).finally(() => {
                    setIsAttendedLoading(false);
                });
        }
    };

    return (
        <>
            <HeaderBar
                isNavigationDrawerOpen={isNavigationDrawerOpen}
                onMenuButtonClick={onMenuButtonClick}
                title="Scan Invitation"
            />
            <Box component="main" sx={{ flexGrow: 1, p: 4.5 }}>
                <DrawerHeader />
                <div id="reader"></div>
                {eventGuest ? (
                    <>
                        <Typography style={{ fontSize: '20px', marginBottom: '16px' }}>
                            Guest's Information
                        </Typography>
                        <Typography>
                            ID: <span style={{ fontWeight: 'bold' }}>{eventGuest.id}</span>
                        </Typography>
                        <Typography>
                            Name: <span style={{ fontWeight: 'bold' }}>{eventGuest.name}</span>
                        </Typography>
                        <Typography>
                            Ref. Code: <span style={{ fontWeight: 'bold' }}>{eventGuest.referenceCode}</span>
                        </Typography>
                        <Typography>
                            Attended at: <span style={{ fontWeight: 'bold' }}>{eventGuest.attendedAt ? dayjs(eventGuest.attendedAt).format('DD-MMM, HH:mm') : 'N/A'}</span>
                        </Typography>
                        {eventGuest.attendedAt === null ? (
                            <Button
                                disabled={isAttendedLoading}
                                variant="contained"
                                style={{ marginTop: '16px' }}
                                onClick={handleConfirmAttendance}
                            >
                                Confirm Attendance
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                style={{ marginTop: '16px', backgroundColor: 'orange' }}
                                onClick={() => window.location.reload()}
                            >
                                Scan another
                            </Button>
                        )}

                        {/* Confirmation Modal */}
                        <SuccessAttendanceModal
                            isOpen={isSuccessAttendanceModalOpen}
                            description={`${eventGuest.name} successfully attended`}
                            onClose={() => window.location.reload()}
                        />
                        <FailureAttendanceModal
                            isOpen={failureAttendanceModalDescription !== null}
                            description={failureAttendanceModalDescription}
                            onClose={() => window.location.reload()}
                        />
                    </>
                ) : null}


            </Box >
        </>
    );
}

export default ScanUserGuestInvitation;