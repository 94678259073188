import { useEffect, useState } from "react";
import HeaderBar from "../../components/HeaderBar";
import { getEvents } from "../../api/event";
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DrawerHeader from "../../components/DrawerHeader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import AddEventModal from "./AddEventModal";

const columns = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'date',
        label: 'Date',
    },
];

const Events = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // const bootstrapAsync = async () => {
        //     try {
        //         const { data } = await getEvents();
        //         setEvents(data);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        // bootstrapAsync();
        handleFetchEvents();
    }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = (shouldRefresh) => {
        setIsModalOpen(false);
        if (shouldRefresh) {
            handleFetchEvents();
        }
    };

    const handleFetchEvents = async () => {
        try {
            const { data } = await getEvents();
            setEvents(data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <HeaderBar
                isNavigationDrawerOpen={isNavigationDrawerOpen}
                onMenuButtonClick={onMenuButtonClick}
                title="Events"
            />
            <Box component="main" sx={{ flexGrow: 1, p: 4.5 }}>
                <DrawerHeader />
                <Grid container spacing={2} sx={{ mt: 0, mb: 4 }} justifyContent={'flex-end'}>
                    <Grid item xs={12} sm={4} md={3} lg={3} container>
                        <Button fullWidth variant="contained" size="large" onClick={handleOpenModal}>
                            Add Event
                        </Button>
                    </Grid>
                </Grid>

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((event) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={event.id}>
                                            {columns.map((column) => {
                                                const value = event[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => navigate(`/events/${event.id}`, { replace: true })}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box >
            <AddEventModal isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} />
        </>
    );
}

export default Events;