import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import NavigationDrawer from './NavigationDrawer';

const DashboardLayout = ({ isDrawerOpen, routes, onMinimizeButtonClick }) => (
  <Box sx={{ display: 'flex' }}>
    <NavigationDrawer
      isOpen={isDrawerOpen}
      onMinimizeButtonClick={onMinimizeButtonClick}
      routes={routes}
    />
    <Outlet />
  </Box>
);

export default DashboardLayout;
