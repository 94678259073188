import { Box, Button, Modal, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessAttendanceModal = ({ isOpen, description, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <CheckCircleIcon style={{ color: 'green', fontSize: 36 }} />
        <Typography variant="h5">Success!</Typography>
        <Typography style={{ margin: '12px' }}>{description}</Typography>
        <Button
          variant="contained"
          fullWidth
          style={{ marginTop: '12px' }}
          onClick={onClose}
        >
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

export default SuccessAttendanceModal;
