import { createContext, useCallback, useEffect, useState } from 'react';
import { login, logout, me } from '../api/auth';
import storage from '../utils/local_storage';

const AuthContext = createContext({
  accessToken: null,
  authData: null,
  logIn: async () => {},
  logOut: async () => {},
  checkAccessToken: async () => {},
});

export function AuthProvider({ children }) {
  const [authData, setAuthData] = useState();
  const [accessToken, setAccessToken] = useState(storage.getToken());

  useEffect(() => {
    if (accessToken) {
      storage.setToken(accessToken);
    } else {
      storage.removeToken();
    }
  }, [accessToken]);

  const logIn = async ({ username, password }) => {
    const { data } = await login({ username, password });
    setAuthData(data.user);
    setAccessToken(data.token);
  };

  const logOut = async () => {
    try {
      if (window.navigator.onLine) {
        await logout();
      }
    } finally {
      setAccessToken(null);
      setAuthData(null);
    }
  };

  const checkAccessToken = useCallback(async () => {
    try {
      const { data } = await me();
      setAuthData(data);
    } catch (error) {
      setAccessToken(null);
      throw error;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ authData, accessToken, logIn, logOut, checkAccessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
