import { axios } from '../utils/axios';

export const getEvents = () => axios.get('events');

export const getEvent = (id) => axios.get(`events/${id}`);

export const addEvent = (name, date) =>
  axios.post('events', {
    name,
    date,
  });
