import * as Yup from 'yup';
import { toast } from "react-toastify";
import { addEvent } from "../../api/event";
import { Button, Modal, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

const AddEventModal = ({ isModalOpen, handleCloseModal }) => {
    const handleSubmit = async (values) => {
        try {
            await addEvent(values.name, values.date.format('YYYY-MM-DD'));
            handleCloseModal(true);
        } catch (error) {
            toast.error(error, { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const AddEventSchema = Yup.object({
        name: Yup.string().required('Tidak boleh kosong'),
        date: Yup.date().required('Tidak boleh kosong'),
    });

    return (
        <Modal open={isModalOpen} onClose={() => handleCloseModal(false)}>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{ name: '', date: '' }}
                validationSchema={AddEventSchema}
            >
                {({ values, handleChange, isSubmitting, setFieldValue, errors, touched }) => (
                    <Form>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', backgroundColor: 'white', boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)' }}>
                            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                                Add Event
                            </Typography>
                            <TextField
                                required
                                label="Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                            />
                            <DatePicker
                                required
                                label="Date"
                                fullWidth
                                margin="normal"
                                name="date"
                                className="w-full"
                                shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), 'day')}
                                sx={{ width: '100%', mt: 2 }}
                                value={values.date}
                                onChange={(date) => setFieldValue('date', date)}
                                format="DD/MMM/YYYY"
                                error={touched.date && Boolean(errors.date)}
                                helperText={touched.date && errors.date}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default AddEventModal;