import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '../pages/auth/Login';
import Forbidden from '../pages/403';
import NoMatch from '../pages/404';
import DashboardRoutes from './dashboard_routes';

const MainRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/403" element={<Forbidden />} />
      <Route path="/*" element={<DashboardRoutes />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default MainRoute;
