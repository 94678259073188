import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DRAWER_WIDTH } from '../constants/navigationDrawerConstants';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HeaderBar = ({ isNavigationDrawerOpen, title, onMenuButtonClick }) => {
  return (
    <AppBar position="fixed" open={isNavigationDrawerOpen} elevation={0}>
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={onMenuButtonClick}
          edge="start"
          sx={{
            marginRight: '36px',
            ...(isNavigationDrawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h5"
          noWrap
          component="div"
          sx={{ flexGrow: 1, fontWeight: 'bold' }}
        >
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

HeaderBar.propTypes = {
  isNavigationDrawerOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
};

export default HeaderBar;
