import React from "react";
import { useNavigate } from "react-router-dom";

const Forbidden = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  
  return (
    <main className="h-screen w-full">
      <section className="font-sans w-full h-full flex flex-col justify-start items-center pt-20">
        <h1 className="text-xl font-bold">403</h1>
        <p className="">Forbidden!</p>
        <p onClick={goBack} className="mt-2 cursor-pointer underline hover:text-primary">
          Go back
        </p>
      </section>
    </main>
  );
};

export default Forbidden;
