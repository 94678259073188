import { useEffect, useState } from "react";
import { getEvent } from "../../api/event";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import HeaderBar from "../../components/HeaderBar";
import DrawerHeader from "../../components/DrawerHeader";
import { getEventGuests } from "../../api/eventGuest";
import QrCodeIcon from "@mui/icons-material/QrCode";
import html2canvas from "html2canvas";
import { render } from "react-dom";
import EventGuestInvitation from "../../components/EventGuestInvitation";
import AddEventGuestModal from "./AddEventGuestModal";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "use-debounce";

const columns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "referenceCode",
    label: "Reference Code",
  },
  {
    id: "attendedAt",
    label: "Attended at",
    format: (value) => (value ? dayjs(value).format("DD-MMM, HH:mm") : "N/A"),
  },
];

const Event = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
  const { id } = useParams();
  const [event, setEvent] = useState();
  const [eventGuests, setEventGuests] = useState();
  const [filter, setFilter] = useState({
    isAttended: null,
    search: null,
  });
  const [debouncedSearch] = useDebounce(filter.search, 500);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    perPage: 10,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (shouldRefresh) => {
    setIsModalOpen(false);
    if (shouldRefresh) {
      handleFetchEventGuests({
        id,
        page: pageInfo.currentPage,
        pageSize: pageInfo.perPage,
        isAttended: filter.isAttended,
        search: debouncedSearch,
      });
    }
  };

  const handleFetchEventGuests = async ({
    id,
    page,
    pageSize,
    isAttended,
    search,
  }) => {
    try {
      const response = await getEventGuests({
        id,
        page,
        pageSize,
        isAttended,
        search,
      });
      setEventGuests(response.data);
      setPageInfo(response.pageInfo);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const { data } = await getEvent(id);
        setEvent(data);
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    bootstrapAsync();
  }, [id]);

  useEffect(() => {
    handleFetchEventGuests({
      id,
      page: pageInfo.currentPage,
      pageSize: pageInfo.perPage,
      isAttended: filter.isAttended,
      search: debouncedSearch,
    });
  }, [
    id,
    pageInfo.currentPage,
    pageInfo.perPage,
    filter.isAttended,
    debouncedSearch,
  ]);

  function handleChangePage(_, newPage) {
    console.log(newPage);
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      currentPage: newPage + 1, // newPage is zero-based
    }));
  }

  function handleChangeRowsPerPage(event) {
    setPageInfo({
      currentPage: 1,
      perPage: event.target.value,
    });
  }

  function handleChangeStatus(event) {
    setFilter({
      ...filter,
      isAttended: event.target.value,
    });
    resetToFirstPage();
  }

  function handleChangeSearch(event) {
    setFilter({
      ...filter,
      search: event.target.value,
    });
    resetToFirstPage();
  }

  function resetToFirstPage() {
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
    });
  }

  async function generateInvitationImage(eventGuest) {
    try {
      // Create a reference to the container element
      const container = document.createElement("div");
      document.body.appendChild(container);

      // Render the content inside the container
      render(
        <EventGuestInvitation event={event} eventGuest={eventGuest} />,
        container
      );

      // Use html2canvas to capture the content as a Data URL with custom dimensions
      const dataUrl = await html2canvas(container, {
        width: 250,
        height: container.clientHeight,
      }).then((canvas) => canvas.toDataURL());

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `invitation_${eventGuest.id}.png`;

      // Trigger a click on the link to initiate the download
      link.click();

      // Remove the container from the DOM
      document.body.removeChild(container);
    } catch (error) {
      console.error("Error generating invitation:", error);
    }
  }

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title="Event Detail"
      />
      <Box component="main" sx={{ flexGrow: 1, p: 4.5 }}>
        <DrawerHeader />
        {eventGuests ? (
          <>
            <Typography>
              Name: <span style={{ fontWeight: "bold" }}>{event?.name}</span>
            </Typography>
            <Typography>
              Date: <span style={{ fontWeight: "bold" }}>{event?.date}</span>
            </Typography>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
              sx={{ mt: 0, mb: 4 }}
            >
              <Grid item xs={3} sm={6} md={12} lg={12}>
                <Typography style={{ fontSize: "20px" }}>Guests</Typography>
              </Grid>
              <Grid item xs={9} sm={6} md={3} lg={3}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filter.isAttended}
                    label="Status"
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"true"}>Attended</MenuItem>
                    <MenuItem value={"false"}>Not Attended</MenuItem>
                    <MenuItem value={null}>All</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl variant="outlined" sx={{ width: "100%" }}>
                  <InputLabel
                    variant="outlined"
                    sx={{
                      paddingRight: 5,
                    }}
                  >
                    Search Name/Reference Code
                  </InputLabel>
                  <OutlinedInput
                    value={filter.search}
                    onChange={handleChangeSearch}
                    id="outlined-adornment-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    label="Search Name/Reference Code"
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                container
                justifyContent={"flex-end"}
              >
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={handleOpenModal}
                >
                  Add Guest
                </Button>
              </Grid>
            </Grid>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 880 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventGuests.map((eventGuest) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={eventGuest.id}
                        >
                          {columns.map((column) => {
                            const value = eventGuest[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                generateInvitationImage(eventGuest)
                              }
                            >
                              <QrCodeIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageInfo.total}
                rowsPerPage={pageInfo.perPage}
                page={pageInfo.currentPage - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
      <AddEventGuestModal
        eventId={event?.id}
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default Event;
