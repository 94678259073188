import Axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import qs from 'qs';
import storage from './local_storage';

export const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) =>
      qs.stringify(decamelizeKeys(params), {
        skipNulls: true,
        arrayFormat: 'comma',
      }),
  },
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use((config) => {
  const token = storage.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.headers['Content-Type'] === 'application/json' && config.data)
    config.data = decamelizeKeys(config.data);

  return config;
});

axios.interceptors.response.use((response) => {
  if (response.headers['content-type'] === 'application/json')
    return camelizeKeys(response.data);

  return response;
});
