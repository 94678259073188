import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';

function RequireAuth({ children }) {
  const { authData, checkAccessToken, accessToken } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        await checkAccessToken();
      } catch (error) {
        console.error(error);
      }
    };

    if (accessToken && !authData) bootstrapAsync();
  }, [accessToken, authData, checkAccessToken]);

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (authData) {
    return children;
  }

  return null;
}

export default RequireAuth;
