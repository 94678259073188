import * as Yup from 'yup';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, Container, TextField, Typography } from "@mui/material";
import { ErrorMessage, Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getCsrfCookie } from '../../api/getCsrfCookie';
import { useContext, useEffect } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { UserRole } from '../../constants/user_role';
import { toast } from 'react-toastify';

const LoginSchema = Yup.object({
  username: Yup.string().required('Tidak boleh kosong'),
  password: Yup.string().required('Tidak boleh kosong'),
});

export const Login = ({ onAuthenticate }) => {
  const navigate = useNavigate();
  const { authData, logIn } = useContext(AuthContext);

  useEffect(() => {
    if (authData) {
      if (authData.role === UserRole.RECEPTIONIST) {
        navigate('/scan-invitation', { replace: true });
      } else {
        navigate('/events', { replace: true });
      }
    }
  },);

  const handleLogin = async (values) => {
    const { username, password } = values;
    try {
      await getCsrfCookie();
      await logIn({ username, password });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Wrong credential");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <Formik
      onSubmit={handleLogin}
      initialValues={{ username: '', password: '' }}
      validationSchema={LoginSchema}
    >
      {({ values, handleChange, isSubmitting }) => (
        <Container maxWidth="sm">
          <Form>
            <Box
              sx={{
                marginTop: 8,
                padding: 4,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main', alignSelf: 'center' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" align='center'>
                Sign in
              </Typography>
              <Box sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="email"
                  autoFocus
                  value={values.username}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="username"
                  component="p"
                  style={{ color: 'red', fontSize: '12px' }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={values.password}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="password"
                  component="p"
                  style={{ color: 'red', fontSize: '12px' }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isSubmitting}
                  onSubmit={handleLogin}
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Form>
        </Container>
      )}
    </Formik >
  );
};
