import { Box, Button, Modal, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const FailureAttendanceModal = ({ isOpen, description, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <ErrorIcon style={{ color: 'red', fontSize: 36 }} />
        <Typography variant="h5" style={{ color: 'red' }}>
          Failure!
        </Typography>
        <Typography style={{ margin: '12px' }}>{description}</Typography>
        <Button
          variant="contained"
          fullWidth
          style={{ marginTop: '12px' }}
          onClick={onClose}
        >
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

export default FailureAttendanceModal;
