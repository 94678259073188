import * as Yup from 'yup';
import { Button, Modal, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { addEventGuest } from '../../api/eventGuest';
import { toast } from 'react-toastify';

const AddEventGuestModal = ({ eventId, isModalOpen, handleCloseModal }) => {
    const handleSubmit = async (values) => {
        try {
            await addEventGuest(eventId, values.name, values.referenceCode);
            handleCloseModal(true);
        } catch (error) {
            toast.error(error, { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const AddEventGuessSchema = Yup.object({
        name: Yup.string().required('Tidak boleh kosong'),
        referenceCode: Yup.string().required('Tidak boleh kosong'),
    });

    return (
        <Modal open={isModalOpen} onClose={() => handleCloseModal(false)}>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{ name: '', referenceCode: '' }}
                validationSchema={AddEventGuessSchema}
            >
                {({ values, handleChange, isSubmitting }) => (
                    <Form>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', backgroundColor: 'white', boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)' }}>
                            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                                Add Guest
                            </Typography>
                            <TextField
                                required
                                label="Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                label="Reference Code"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="referenceCode"
                                value={values.referenceCode}
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={isSubmitting}
                                onSubmit={handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>

                )}

            </Formik>
        </Modal>
    );
}

export default AddEventGuestModal;